const serviceHost = "/";
const servicePort = "http://47.108.233.4:9092/"; // 8080
const WebSocket_dev_Url = "ws://47.108.233.4:9092/"
const WebSocket_pro_Url = "/"
const wsChatFilepath = "rtBusinessBackend"
const serviceName = ""; // FishFamily
const chenID = "";

const filePath='rtBusinessBackend'

export default {
  serviceHost,
  servicePort,
  serviceName,
  chenID,
  filePath,
  WebSocket_dev_Url,
  WebSocket_pro_Url,
  wsChatFilepath
};
