import axios from 'axios'
// import router from '../router'
// import qs from "qs";
// import ElementUI from 'element-ui';
import router from '../router'
import store from '@/store/index.js'
import { Notification } from 'element-ui';
import Config from "../utils/httpConfig";
let requestList = new Set() // 存储请求url
    // alert(process.env.NODE_ENV)
let NOListRequest = new Set([
   
])
const url = process.env.NODE_ENV === 'development' ? Config.servicePort : Config.serviceHost
const service = axios.create({
        // api的base_url
        baseURL: url+Config.filePath,
        // baseURL: '/api',
        // baseURL: process.env.BASE_URL,
        timeout: 60000,
        // headers: {"Access-Control-Allow-Credentials": "true"},
        withCredentials: true,
        crossDomain: true,
        // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
      
    })

    // request拦截器
service.interceptors.request.use(config => {
     console.log(config)
     const qyyToken = localStorage.getItem('qyyToken')
     // 将token添加到请求头
     if (qyyToken) {
       config.headers.Authorization = `${qyyToken}`
     }
    // if (localStorage.getItem("access_token")) {
    //     config.headers.common['Authorization'] = "Bearer " + localStorage.getItem("access_token");
    // }

        // console.log(requestList)

    return config;
}, error => {
    // store.commit('loading/hideLoading')
    // Do something with request error
    console.log('request拦截器1', error) // for debug
    Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(response => {
    // console.log("111111111")
    // console.log(response.data.message)

    // console.log("45646")
    return response.data;
}, error => {
    // 相同请求不得在600毫秒内重复发送，反之继续执行
    console.log('respone拦截器22' + error) // for debug
    console.log(JSON.stringify(error))
        // 对响应错误做点什么
        // Notification({
        //   title: '请求错误',
        //   message: `${error}`
        // });
        //   const response = error.response
        //   if (response === undefined) {
        //     // store.commit('loading/hideLoading')
        //     // 跳转到登录页
        //     // router.push('/')
        //     return Promise.reject(error)
        //   }
        //   const info = response.data
        const response = error.response
          //401返回登录
          if (response?.status === 401) {
            localStorage.removeItem('userInfo')
            localStorage.removeItem('qyyToken')
            localStorage.removeItem('phone')
            localStorage.removeItem('name')
            localStorage.removeItem('resourceTree')
            localStorage.removeItem('expireTime')
            // 跳转到登录页
            router.push('/')
            return Promise.reject(error)
          }
          const info = response.data

})

export default service