import Vue from 'vue'
import { initPxToRem } from '@/utils/tools.js'
import App from './App.vue'
import router from './router'
import store from './store'
import http from "./utils/request";
import ElementUI from 'element-ui';
import NProgress from "nprogress";
import './assets/css/reset.css';
import "nprogress/nprogress.css";
import './assets/css/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; 

Vue.prototype.$axios = http;
ElementUI.Dialog.props.closeOnClickModal.default = false;
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false
})

locale.el.pagination.goto = '跳至';

Vue.config.productionTip = false

//顶部加载进度条
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

//顶部加载进度条
router.afterEach(() => {
  NProgress.done();
});


Vue.use(ElementUI);
initPxToRem(1920)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



Vue.prototype.resetSetItem = function (key, newVal) {
  if (key == `chatList${localStorage.getItem('name')}`) {

      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              localStorage.setItem(k, val);

              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

              // 派发对象
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
}