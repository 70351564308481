import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/passwordRecovery',
    name: 'passwordRecovery',
    component: () => import('../views/login/passwordRecovery.vue')
  },
  {
    path: '/configPassword',
    name: 'configPassword',
    component: () => import('../views/login/configPassword.vue')
  },
  {
    path: '/recoverySuccess',
    name: 'recoverySuccess',
    component: () => import('../views/login/recoverySuccess.vue')
  },
  {
    path: '/phoneRegister',
    name: 'phoneRegister',
    component: () => import('../views/login/phoneRegister.vue')
  },
  {
    path: '/enterprisesInto',
    name: 'enterprisesInto',
    component: () => import('../views/enterprisesInto/enterprisesInto.vue'),
    meta: {
        menuName: '个人中心',
       
     },
   },
   
   {
    path: '/enterprisesIntoFormOne',
    name: 'enterprisesIntoFormOne',
    component: () => import('../views/enterprisesInto/enterprisesIntoFormOne.vue'),
    meta: {
        menuName: '入驻',
       
     },
   },

   {
    path: '/enterprisesIntoFormTwo',
    name: 'enterprisesIntoFormTwo',
    component: () => import('../views/enterprisesInto/enterprisesIntoFormTwo.vue'),
    meta: {
        menuName: '个人中心',
     },
   },
   {
    path: '/enterprisesIntoFormThree',
    name: 'enterprisesIntoFormThree',
    component: () => import('../views/enterprisesInto/enterprisesIntoFormThree.vue'),
    meta: {
        menuName: '个人中心',
     },
   },

  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    children: [
          // 首页
          {
            path: '/mainHome',
            name: 'mainHome',
            component: () => import('../views/main/main.vue'),
            meta: {
                menuName: '首页',
                icon: 'home',
                showNav: true,//是否显示在导航栏
                module: '/mainHome',//模块名称
            },
          },
          //公告详情
          {
            path: '/noticeDetails',
            name: 'noticeDetails',
            component: () => import('../views/main/noticeDetails.vue'),
            meta: {
              menuName: '公告详情',
              icon: 'home',
              showNav: false,//是否显示在导航栏
              module: '/mainHome',//模块名称
            },
          },
           // 代发薪资服务页
           {
            path: '/shippingSalaryServices',
            name: 'shippingSalaryServices',
            component: () => import('../views/shippingSalaryServices/shippingSalaryServices.vue'),
            meta: {
                menuName: '代发薪资服务',
                icon: 'shippingSalaryServices',
                showNav: true,//是否显示在导航栏
                module: '/shippingSalaryServices',//模块名称
             }

           },
           //代发薪资服务详情
           {
            path: '/shippingDetails',
            name: 'shippingDetails',
            component: () => import('../views/shippingSalaryServices/shippingDetails.vue'),
            meta: {
                menuName: '代发薪资服务详情',
                icon: 'shippingSalaryServices',
                showNav: false,//是否显示在导航栏
                module: '/shippingSalaryServices',//模块名称
             }

           },
           //灵活任务管理
           {
            path: '/flexibleTaskManagement',
            name: 'flexibleTaskManagement',
            component: () => import('../views/flexibleTaskManagement/flexibleTaskManagement.vue'),
            meta: {
                menuName: '灵活任务管理',
                icon: 'flexibleTaskManagement',
                showNav: true,//是否显示在导航栏
                module: '/flexibleTaskManagement',//模块名称
             },
           },
           {
            path: '/flexibleDetails',
            name: 'flexibleDetails',
            component: () => import('../views/flexibleTaskManagement/flexibleDetails.vue'),
            meta: {
                menuName: '灵活任务详情',
                icon: 'flexibleTaskManagement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskManagement',//模块名称
             },
           },
           
            //灵活任务结算
           {
            path: '/publishTasks',
            name: 'publishTasks',
            component: () => import('../views/flexibleTaskManagement/publishTasks.vue'),
            meta: {
                menuName: '发布灵活任务',
                icon: 'flexibleTaskManagement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskManagement',//模块名称
             },
           },

           //灵活任务结算
           {
            path: '/flexibleTaskSettlement',
            name: 'flexibleTaskSettlement',
            component: () => import('../views/flexibleTaskSettlement/flexibleTaskSettlement.vue'),
            meta: {
                menuName: '灵活任务结算',
                icon: 'flexibleTaskSettlement',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/flexSettlementDetails',
            name: 'flexSettlementDetails',
            component: () => import('../views/flexibleTaskSettlement/flexSettlementDetails.vue'),
            meta: {
                menuName: '灵活任务结算详情',
                icon: 'flexibleTaskSettlement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskSettlement',//模块名称
             },
           },
           {
            path: '/startSettlementOne',
            name: 'startSettlementOne',
            component: () => import('../views/flexibleTaskSettlement/startSettlementOne.vue'),
            meta: {
                menuName: '发起结算1',
                icon: 'flexibleTaskSettlement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskSettlement',//模块名称
             },
           },
           {
            path: '/startSettlementTwo',
            name: 'startSettlementTwo',
            component: () => import('../views/flexibleTaskSettlement/startSettlementTwo.vue'),
            meta: {
                menuName: '发起结算2',
                icon: 'flexibleTaskSettlement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskSettlement',//模块名称
             },
           },
           {
            path: '/startSettlementThree',
            name: 'startSettlementThree',
            component: () => import('../views/flexibleTaskSettlement/startSettlementThree.vue'),
            meta: {
                menuName: '发起结算3',
                icon: 'flexibleTaskSettlement',
                showNav: false,//是否显示在导航栏
                module: '/flexibleTaskSettlement',//模块名称
             },
           },
           //全职管理
           {
            path: '/fullTimeManagement',
            name: 'fullTimeManagement',
            component: () => import('../views/fullTimeManagement/fullTimeManagement.vue'),
            meta: {
                menuName: '全职管理',
                icon: 'fullTimeManagement',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/fullTimeDetails',
            name: 'fullTimeDetails',
            component: () => import('../views/fullTimeManagement/fullTimeDetails.vue'),
            meta: {
                menuName: '全职管理详情',
                icon: 'fullTimeManagement',
                showNav: false,//是否显示在导航栏
                module: '/fullTimeManagement',//模块名称
             },
           },
           {
            path: '/addPost',
            name: 'addPost',
            component: () => import('../views/fullTimeManagement/addPost.vue'),
            meta: {
                menuName: '全职管理详情',
                icon: 'fullTimeManagement',
                showNav: false,//是否显示在导航栏
                module: '/fullTimeManagement',//模块名称
             },
           },

           //发票管理
           {
            path: '/invoicesManagement',
            name: 'invoicesManagement',
            component: () => import('../views/invoicesManagement/invoicesManagement.vue'),
            meta: {
                menuName: '发票管理',
                icon: 'invoicesManagement',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/addInvoicesType',
            name: 'addInvoicesType',
            component: () => import('../views/invoicesManagement/addInvoicesType.vue'),
            meta: {
                menuName: '发票管理',
                icon: 'invoicesManagement',
                showNav: false,//是否显示在导航栏
                module: '/invoicesManagement',//模块名称
             },
           },
           

            
           //发放查询
           {
            path: '/distributionQuery',
            name: 'distributionQuery',
            component: () => import('../views/distributionQuery/distributionQuery.vue'),
            meta: {
                menuName: '发放查询',
                icon: 'distributionQuery',
                showNav: true,//是否显示在导航栏
             },
           },

           //账户明细
           {
            path: '/accountDetails',
            name: 'accountDetails',
            component: () => import('../views/accountDetails/accountDetails.vue'),
            meta: {
                menuName: '账户明细',
                icon: 'accountDetails',
                showNav: true,//是否显示在导航栏
             },
           },

           //消息管理
           {
            path: '/messageManagement',
            name: 'messageManagement',
            component: () => import('../views/messageManagement/messageManagement.vue'),
            meta: {
                menuName: '消息管理',
                icon: 'messageManagement',
                showNav: true,//是否显示在导航栏
                module:'/messageManagement'
             },
           },
            
           //企业资料编辑
           {
            path: '/enterpriseDataEdit',
            name: 'enterpriseDataEdit',
            component: () => import('../views/enterpriseDataEdit/enterpriseDataEdit.vue'),
            meta: {
                menuName: '企业资料编辑',
                icon: 'enterpriseDataEdit',
                showNav: true,//是否显示在导航栏
             },
           },

           //企业相册管理
           {
            path: '/enterpriseAlbumManagement',
            name: 'enterpriseAlbumManagement',
            component: () => import('../views/enterpriseAlbumManagement/enterpriseAlbumManagement.vue'),
            meta: {
                menuName: '企业相册管理',
                icon: 'enterpriseAlbumManagement',
                showNav: true,//是否显示在导航栏
             },
           },

           {
            path: '/albumDetails',
            name: 'albumDetails',
            component: () => import('../views/enterpriseAlbumManagement/albumDetails.vue'),
            meta: {
                menuName: '相册详情',
                icon: 'enterpriseAlbumManagement',
                showNav: false,//是否显示在导航栏
                module: '/enterpriseAlbumManagement',//模块名称
             },
           },
           
           //产品展示管理
           {
            path: '/productDisplayManagement',
            name: 'productDisplayManagement',
            component: () => import('../views/productDisplayManagement/productDisplayManagement.vue'),
            meta: {
                menuName: '产品展示管理',
                icon: 'productDisplayManagement',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/addgoods',
            name: 'addgoods',
            component: () => import('../views/productDisplayManagement/addgoods.vue'),
            meta: {
                menuName: '产品展示管理',
                icon: 'productDisplayManagement',
                showNav: false,//是否显示在导航栏
                module: '/productDisplayManagement',//模块名称
             },
           },

           //外加工管理
           {
            path: '/outsourcingManagement',
            name: 'outsourcingManagement',
            component: () => import('../views/outsourcingManagement/outsourcingManagement.vue'),
            meta: {
                menuName: '外加工管理',
                icon: 'outsourcingManagement',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/addReleaseReq',
            name: 'addReleaseReq',
            component: () => import('../views/outsourcingManagement/addReleaseReq.vue'),
            meta: {
                menuName: '外加工管理',
                icon: 'outsourcingManagement',
                showNav: false,//是否显示在导航栏
                module: '/outsourcingManagement',//模块名称
             },
           },
          
           //个人中心
           {
            path: '/userCenter',
            name: 'userCenter',
            component: () => import('../views/userCenter/userCenter.vue'),
            meta: {
                menuName: '个人中心',
                icon: 'userCenter',
                showNav: true,//是否显示在导航栏
             },
           },
           {
            path: '/updataPassWord',
            name: 'updataPassWord',
            component: () => import('../views/userCenter/updataPassWord.vue'),
            meta: {
                menuName: '个人中心',
                icon: 'userCenter',
                showNav: false,//是否显示在导航栏
                module: '/userCenter',//模块名称
             },
           },     
           
           {
            path: '/userCenterDetails',
            name: 'userCenterDetails',
            component: () => import('../views/userCenter/userCenterDetails.vue'),
            meta: {
                menuName: '个人中心',
                icon: 'userCenter',
                showNav: false,//是否显示在导航栏
                module: '/userCenter',//模块名称
             },
           },     


          
           
           
           
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

router.beforeEach((to, from, next) => {
    next()
});
 

export default router
