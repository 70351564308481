import Vue from 'vue'
import Vuex from 'vuex'
import Config from "../utils/httpConfig"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    websocket: null,
    isReadnum:localStorage.getItem(localStorage.getItem('name')+'isReadnum'),
  },
  getters: {
  },
  mutations: {
    setWebsocket(state, payload) {
      state.websocket = payload;
    },
    getisReadnum(state,payload){
      state.isReadnum = payload;
      localStorage.setItem(localStorage.getItem('name') +'isReadnum',state.isReadnum)
    }
  },
  actions: {
    initWebSocket({ commit }) {
      const token = localStorage.getItem('qyyToken')
      const WebSocketUrl=process.env.NODE_ENV === 'development'
              ? Config.WebSocket_dev_Url
              : window.location.port
              ? `${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${
                  window.location.hostname
                }:${window.location.port}${Config.WebSocket_pro_Url}`
              : `${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.hostname}${
                  Config.WebSocket_pro_Url
                }`
      const filepath = Config.wsChatFilepath
      const websocket = new WebSocket(`${WebSocketUrl}${filepath}/WSServer/${token}`) // WebSocket 服务器地址
      commit('setWebsocket', websocket);
    },
  },
  modules: {
  }
})
