function initPxToRem(fullWidth) {
    function init(fullWidth) {
        const baseFontSize = 16;
        const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
        // console.log(fullWidth);
        // console.log(clientWidth);
        const scale = clientWidth / fullWidth;
        // console.log(scale);
        const rootFontSize = scale * baseFontSize;
        // console.log(rootFontSize);
        const html = document.getElementsByTagName('html')[0];
        html.style.fontSize = rootFontSize + 'px';
    }

    init(fullWidth);

    window.addEventListener('resize', () => {
        init(fullWidth);
    });
}



export { initPxToRem,}